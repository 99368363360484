import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Signin from "./Signin";
import { useAuth } from "./AuthContext";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from "./config/API";
import { useToast } from "./components/Toast";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

export default function CampaignsEdit() {
  const navigate = useNavigate();
  const [target, setTarget] = useState("");
  const { id } = useParams();
  const {
    isLoggedIn,
    userId,
    token,
    userType,
    partnerCode,
    apiUrl,
    isLoading,
    setLoader,
    unsetLoader,
  } = useAuth();
  const [formData, setFormData] = useState({
    name: "",
    client: "",
    file_name: "",
    input: "",
    input_class: "",
    output: "",
    output_class: "",
    status: 0,
  });
  const toaster = useToast();

  useEffect(() => {
    const fetchCampaignData = async () => {
      try {
        setLoader();
        const response = await API.get(`/campaign/${id}`);
        unsetLoader();
        const data = response.data.data;
        data.input = JSON.stringify(data.input, null, 2);
        data.output = JSON.stringify(data.output, null, 2);
        setFormData(data);
        setTarget(response.data.data.name);
      } catch (error) {
        unsetLoader();
        console.error("Error fetching partner data:", error);
      }
    };
    fetchCampaignData();
  }, [id]);

  // Handler for form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      for (const key in formData) {
        if (key != "expiry_period_custom") {
          if (!formData[key]) {
            toaster(
              {
                text: `${key} non puo' essere vuoto!`,
              },
              { type: "error" },
            );
            return false;
          }
        }
        if (key == "annual_offers_budget") {
          formData["annual_offers_budget"] = convertEuropeanToNumeric(
            formData["annual_offers_budget"],
          ); //.replace(".","").replace(",",".")
        }
        if (key == "annual_orders_budget") {
          formData["annual_orders_budget"] = convertEuropeanToNumeric(
            formData["annual_orders_budget"],
          ); //.replace(".","").replace(",",".")
        }
        if (
          key == "expiry_period_custom" &&
          (isNaN(formData[key]) || parseInt(formData[key]) <= 0)
        ) {
          toaster(
            {
              text: `La scadenza libera deve essere un numero superiore a 0!`,
            },
            { type: "error" },
          );
          return false;
        }
      }
      setLoader();
      console.log(formData);
      //return false;
      const response = await API.post(`/partners`, formData);
      console.log(response.data); // Handle success
      if (response.data.message == "ok") {
        unsetLoader();
        toaster(
          {
            text: `${response.data.data}`,
          },
          { type: "success" },
        );
        setTimeout(() => {
          navigate("/partners");
        }, 1000);
      } else {
        unsetLoader();
        toaster(
          {
            text: `${response.data.data}`,
          },
          { type: "error" },
        );
      }
    } catch (error) {
      unsetLoader();
      toaster(
        { text: `Si è verificato un errore: ${error}` },
        { type: "error" },
      );
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  if (!isLoggedIn) return <Signin />;

  return (
    <div style={{ height: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <h1>Edit Campaign - {target}</h1>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Client"
                  name="client"
                  value={formData.client}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="File Root"
                  name="file_name"
                  value={formData.file_name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Input Fields Schema"
                  name="input"
                  value={formData.input}
                  onChange={handleChange}
                  multiline
                  rows={4} // Adjust the number of rows as needed
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Input Class"
                  name="input_class"
                  value={formData.input_class}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Output Fields Schema"
                  name="output"
                  value={formData.output}
                  onChange={handleChange}
                  multiline
                  rows={4} // Adjust the number of rows as needed
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Output Class"
                  name="class"
                  value={formData.output_class}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Endpoint"
                  name="endpoint"
                  value={apiUrl + "/in/" + formData._id}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="partners">Status:</InputLabel>
                  <Select
                    labelId="subpartners"
                    name="subpartners"
                    value={formData.status}
                    onChange={handleChange}
                  >
                    <MenuItem value="1">Active</MenuItem>
                    <MenuItem value="0">Not Active</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ backgroundColor: "#0AF17D", color: "white" }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Box>
      <ToastContainer
        hideProgressBar
        icon={<></>}
        closeButton={false}
        toastStyle={{ background: "transparent", boxShadow: "none" }}
      />
    </div>
  );
}
