import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Signin from "./Signin";
import { useAuth } from "./AuthContext";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Checkbox,
  Grid,
  InputLabel,
} from "@mui/material";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FormControl, MenuItem, Select } from "@mui/material";
import { useToast } from "./components/Toast";
import API from "./config/API";
import { useNavigate } from "react-router-dom";

export default function Reports() {
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");
  const [searchCampaign, setSearchCampaign] = useState("null");
  const [searchStartDate, setSearchStartDate] = useState("null");
  const [searchEndDate, setSearchEndDate] = useState("null");
  const [searchStep, setSearchStep] = useState("null");
  const [rows, setRows] = useState([]);
  const [opportunities, setOpportunities] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [campaign, setCampaign] = useState("");
  const {
    isLoggedIn,
    partnerCode,
    token,
    apiUrl,
    userType,
    isLoading,
    setLoader,
    unsetLoader,
  } = useAuth();

  const toaster = useToast();

  const getCampaignName = (id) => {
    const campaign = campaigns.find((c) => c._id === id);
    return campaign ? campaign.name : "";
  };

  const fetchCampaigns = async () => {
    try {
      setLoader();
      if (isLoggedIn == false) {
        return false;
      }
      const url = `/campaigns/null/null`;
      const response = await API.get(url);
      unsetLoader();
      console.log(response.data.data);
      setCampaigns(response.data.data);
    } catch (error) {
      unsetLoader();
      toaster(
        { text: `Si è verificato un errore: ${error}` },
        { type: "error" },
      );
    }
  };

  const fetchData = async () => {
    console.log(searchStartDate);
    console.log(searchEndDate);
    try {
      setLoader();
      if (isLoggedIn == false) {
        return false;
      }
      /*const currentSearchStartDate =
        searchStartDate === "null" || searchStartDate === null
          ? "null"
          : moment(searchStartDate["$d"]).format("YYYY-MM-DD");

      const currentSearchEndDate =
        searchEndDate === "null" || searchEndDate === null
          ? "null"
          : moment(searchEndDate["$d"]).format("YYYY-MM-DD");*/

      const _searchInput = searchInput === "" ? "null" : searchInput;
      //const currentSearchCampaign = id;

      const url = `/reports/${_searchInput}`;
      ///${currentSearchCampaign}/${currentSearchStartDate}/${currentSearchEndDate}/${searchStep}
      console.log(url);
      //return false;
      const response = await API.get(url);
      unsetLoader();
      setRows(response.data.data);
    } catch (error) {
      unsetLoader();
      toaster(
        { text: `Si è verificato un errore: ${error}` },
        { type: "error" },
      );
    }
  };

  useEffect(() => {
    //fetchCampaigns();
    fetchData();
  }, [searchInput]);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 200,
      editable: true,
    },
    {
      field: "campaign_name",
      headerName: "Campaign",
      width: 160,
      editable: true,
      /* renderCell: (params) => {
       // console.log(params)
        const campaignName = getCampaignName(params.value);
          return (
            <div style={{ textAlign: "center" }}>
             {campaignName}
            </div>
          );
        }*/
    },
    {
      field: "start_date",
      headerName: "Start Date",
      width: 150,
      editable: true,
    },
    {
      field: "end_date",
      headerName: "End Date",
      width: 150,
      editable: true,
    },
    {
      field: "cpl",
      headerName: "CPL",
      width: 150,
      editable: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 470,
      sortable: false,
    },
    {
      /* {
      field: "actions",
      headerName: "Actions",
      width: 200,
      sortable: false,
      renderCell: (params) => (
        <div>
          <Link to={`/exclusives/edit/${params.row._id}`}>
            <Button
              size="small"
              variant="outlined"
              sx={{ marginRight: "4px", lineHeight: "0px", height: "31px" }}
            >
              Modifica
            </Button>
          </Link>
          <Button
            size="small"
            variant="outlined"
            onClick={() => handleNotes(params.row._id)}
            sx={{ lineHeight: "0px", height: "31px" }}
          >
            Note1
          </Button>
        </div>
      ),
    },*/
    },
  ];

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  /*const handleSearchCampaignChange = (event) => {
    setSearchCampaign(event.target.value);
  };

  const handleSearchStepChange = (event) => {
    setSearchStep(event.target.value);
  };

  const handleSearchStartDateChange = (date) => {
    setSearchStartDate(date);
  };

  const handleSearchEndDateChange = (date) => {
    setSearchEndDate(date);
  };*/

  const handleExportXls = async () => {
    try {
      setLoader();
      if (isLoggedIn == false) {
        return false;
      }
      const currentSearchStartDate =
        searchStartDate === "null"
          ? "null"
          : moment(searchStartDate["$d"]).format("YYYY-MM-DD");

      const currentSearchEndDate =
        searchEndDate === "null"
          ? "null"
          : moment(searchEndDate["$d"]).format("YYYY-MM-DD");

      const _searchInput = searchInput === "" ? "null" : searchInput;

      const url = `/export_leads/${_searchInput}/${searchCampaign}/${currentSearchStartDate}/${currentSearchEndDate}/${searchStep}`;
      console.log(url);
      //return false;
      const response = await API.get(url);
      unsetLoader();
      if (response.data.message == "ok") {
        window.open(`${response.data.data}`);
      }
    } catch (error) {
      unsetLoader();
      toaster(
        { text: `Si è verificato un errore: ${error}` },
        { type: "error" },
      );
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    if (isNotes == true) {
      setIsNotes(false);
    }
    if (isPartners == true) {
      setIsPartners(false);
    }
    if (isCreateNote == true) {
      setIsCreateNote(false);
    }
    if (isExtension == true) {
      setIsExtension(false);
      setIsExtensionOffers(false);
      setIsExtensionOrders(false);
    }
    setNoteValue(undefined);
    setExclusiveId(undefined);
    setExclusivePartnerCode(undefined);
  };

  if (!isLoggedIn) return <Signin />;

  // Pass handleNotes function as a prop to the columns array
  const columnsWithActions = columns.map((column) => {
    if (column.field === "actions") {
      return {
        ...column,
        renderCell: (params) => (
          <div>
            <Link to={`/opportunities/edit/${params.row._id}`}>
              <Button
                size="small"
                variant="outlined"
                sx={{
                  marginRight: "4px",
                  lineHeight: "0px",
                  height: "31px",
                }}
                style={{
                  marginRight: "10px",
                  backgroundColor: "#0AF17D",
                  color: "white",
                  border: "none",
                }}
              >
                Edit
              </Button>
            </Link>
          </div>
        ),
      };
    }
    return column;
  });
  let index = 0;
  return (
    <div>
      <Box>
        <h1>Reports </h1>
        {userType === "admin" && (
          <div className="customDatePicker" style={{ marginBottom: "10px" }}>
            <TextField
              size="small"
              label="Search"
              type="search"
              variant="outlined"
              style={{ marginBottom: "10px" }}
              value={searchInput}
              onChange={handleSearchInputChange}
            />
            {/*&nbsp;
            <Select
              size="small"
              value={searchCampaign}
              onChange={handleSearchCampaignChange}
            >
              <MenuItem value="null">All Campaigns</MenuItem>
              {opportunities.map((campaign) => (
              <MenuItem key={campaign._id} value={campaign._id}>
                {campaign.name}
              </MenuItem>
            ))}
          </Select>
            &nbsp;
            <DatePicker format="DD/MM/YYYY" onChange={handleSearchStartDateChange} />
            &nbsp;
            <DatePicker format="DD/MM/YYYY" onChange={handleSearchEndDateChange} />
            &nbsp;
            <Select
              size="small"
              value={searchStep}
              onChange={handleSearchStepChange}
            >
              <MenuItem value="null">Steps</MenuItem>
              <MenuItem value="1">Input</MenuItem>
              <MenuItem value="2">Validation</MenuItem>
              <MenuItem value="3">Output</MenuItem>
            </Select>
            &nbsp;
            <Button
              variant="contained"
              color="primary"
              onClick={handleExportXls}
              style={{ backgroundColor: "#0AF17D", color: "white" }}
            >
              Export Excel
            </Button>*/}
            &nbsp;
            <Button
              variant="contained"
              color="primary"
              onClick={fetchData}
              style={{ backgroundColor: "#0AF17D", color: "white" }}
            >
              Reload
            </Button>
          </div>
        )}
        <Box>
          <DataGrid
            className="dataGrid"
            isCellEditable={() => false}
            rows={rows}
            columns={columnsWithActions} // Use the modified columns array
            initialState={{ pagination: { paginationModel: { pageSize: 15 } } }}
            pageSizeOptions={[15]}
            disableRowSelectionOnClick
            disableSelectionOnClick
            disableColumnMenu
            getRowClassName={(params) => {
              return params.indexRelativeToCurrentPage % 2 === 0
                ? "evenRow"
                : "oddRow";
              //params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow' : 'oddRow'
            }}
          />
        </Box>
      </Box>
      <ToastContainer
        hideProgressBar
        icon={<></>}
        closeButton={false}
        toastStyle={{ background: "transparent", boxShadow: "none" }}
      />
    </div>
  );
}
