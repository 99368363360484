import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Signin from "./Signin";
import { useAuth } from "./AuthContext";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Checkbox,
  Grid,
  InputLabel
} from "@mui/material";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FormControl, MenuItem, Select } from "@mui/material";
import { useToast } from "./components/Toast";
import API from "./config/API";
import { useNavigate } from "react-router-dom";



export default function Leads() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchInput, setSearchInput] = useState("");
  const [searchCampaign, setSearchCampaign] = useState("null");
  const [searchStartDate, setSearchStartDate] = useState("null");
  const [searchEndDate, setSearchEndDate] = useState("null");
  const [searchStep, setSearchStep] = useState("null");
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [campaigns, setCampaigns] = useState([])
  const [campaign, setCampaign] = useState("")
  const {
    isLoggedIn,
    partnerCode,
    token,
    apiUrl,
    userType,
    isLoading,
    setLoader,
    unsetLoader,
  } = useAuth();

  const toaster = useToast();


  const getCampaignName = (id) => {
    const campaign = campaigns.find(c => c.id === id);
    return campaign.name
  }

  const fetchCampaigns = async () => {
    try {
      setLoader();
      if (isLoggedIn == false) {
        return false;
      }
      const url = `/campaigns/null/null`
      const response = await API.get(url);
      unsetLoader();
      console.log(response.data.data)
      setCampaigns(response.data.data);
      const item = response.data.data.find(element => element._id === id);
      setCampaign(item.name)
    } catch (error) {
      unsetLoader();
      toaster({text: `Si è verificato un errore: ${error}`,},{ type: "error" });
    }
  }

  const changeFieldHeader = (key) => {
    if (key=='valid_1') {
      return "Input"
    } else if(key=='valid_2') {
      return "Valid"
    } else if(key=='valid_2_score') {
      return "Score"
    } else if(key=='valid_3') {
      return "Output"
    } else {
      return key.charAt(0).replace("_"," ").toUpperCase() + key.slice(1).replace("_"," ")
    }
  }

  const fetchData = async () => {
    console.log(searchStartDate)
    console.log(searchEndDate)
    try {
      setLoader();
      if (isLoggedIn == false) {
        return false;
      }
      const currentSearchStartDate =
        searchStartDate === "null" || searchStartDate === null
          ? "null"
          : moment(searchStartDate["$d"]).format("YYYY-MM-DD");

      const currentSearchEndDate =
        searchEndDate === "null" || searchEndDate === null
          ? "null"
          : moment(searchEndDate["$d"]).format("YYYY-MM-DD");


      const _searchInput = searchInput === "" ? "null" : searchInput;
      const currentSearchCampaign = id;
      
      const url = `/leads/${_searchInput}/${currentSearchCampaign}/${currentSearchStartDate}/${currentSearchEndDate}/${searchStep}`
      console.log(url)
      //return false;
      const response = await API.get(url);
      unsetLoader();
      if (response.data.data.length > 0) {
        const keys = Object.keys(response.data.data[0]);
        const excludedFields = ["_id", "id", "campaign_id", "log_id"];
        const prioritizedFields = ["name", "lastname"];
        const prioritizedFields2 = ["email"];
        const prioritizedFields3 = ["phone"];
        const lastFields = ["valid_1", "valid_2", "valid_2_score", "valid_3", "revenue", "date"];
      
        // Filter out the excluded fields and fields that should be first or last
        const dynamicColumns = keys
          .filter(key => !excludedFields.includes(key) && !prioritizedFields.includes(key) && !prioritizedFields2.includes(key) && !prioritizedFields3.includes(key) && !lastFields.includes(key))
          .map(key => ({
            field: key,
            headerName: key.charAt(0).replace("_"," ").toUpperCase() + key.slice(1).replace("_"," "),
            width: 100,
            editable: false,
          }));
      
        // Add the prioritized fields at the beginning
        const prioritizedColumns = prioritizedFields.map(key => ({
          field: key,
          headerName: key.charAt(0).toUpperCase() + key.slice(1),
          width: 120,
          editable: false,
        }));

        const prioritizedColumns2 = prioritizedFields2.map(key => ({
          field: key,
          headerName: key.charAt(0).toUpperCase() + key.slice(1),
          width: 260,
          editable: false,
        }));

        const prioritizedColumns3 = prioritizedFields3.map(key => ({
          field: key,
          headerName: key.charAt(0).toUpperCase() + key.slice(1),
          width: 150,
          editable: false,
        }));
      
        // Add the last fields at the end
        const lastColumns = lastFields.map(key => ({
          field: key,
          headerName: changeFieldHeader(key),
          width: key === "date" ? 120 : 80, 
          editable: false,
        }));
      
        
        const combinedColumns = [...prioritizedColumns, ...prioritizedColumns2, ...prioritizedColumns3, ...dynamicColumns, ...lastColumns];
      
        setColumns(combinedColumns);
      }
      setRows(response.data.data);
    } catch (error) {
      unsetLoader();
      toaster({text: `Si è verificato un errore: ${error}`,},{ type: "error" });
    }
  };

  useEffect(() => {
    console.log(id)
    setSearchCampaign(id)
    fetchData(id);
  }, [searchInput, searchStartDate, searchEndDate, searchCampaign, searchStep, isLoggedIn]);

  useEffect(() => {
    fetchCampaigns();
  }, []);

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleSearchCampaignChange = (event) => {
    setSearchCampaign(event.target.value);
  };

  const handleSearchStepChange = (event) => {
    setSearchStep(event.target.value);
  };

  const handleSearchStartDateChange = (date) => {
    setSearchStartDate(date);
  };

  const handleSearchEndDateChange = (date) => {
    setSearchEndDate(date);
  };

 
  const handleExportXls = async () => {
    try {
      setLoader();
      if (isLoggedIn == false) {
        return false;
      }
      const currentSearchStartDate =
        searchStartDate === "null"
          ? "null"
          : moment(searchStartDate["$d"]).format("YYYY-MM-DD");

      const currentSearchEndDate =
        searchEndDate === "null"
          ? "null"
          : moment(searchEndDate["$d"]).format("YYYY-MM-DD");


      const _searchInput = searchInput === "" ? "null" : searchInput;
      
      const url = `/export_leads/${_searchInput}/${searchCampaign}/${currentSearchStartDate}/${currentSearchEndDate}/${searchStep}`
      console.log(url)
      //return false;
      const response = await API.get(url);
      unsetLoader();
      if (response.data.message == "ok") {
        window.open(`${response.data.data}`);
      }
    } catch (error) {
      unsetLoader();
      toaster({text: `Si è verificato un errore: ${error}`,},{ type: "error" });
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    if (isNotes == true) {
      setIsNotes(false);
    }
    if (isPartners == true) {
      setIsPartners(false);
    }
    if (isCreateNote == true) {
      setIsCreateNote(false);
    }
    if (isExtension == true) {
      setIsExtension(false);
      setIsExtensionOffers(false)
      setIsExtensionOrders(false)
    }
    setNoteValue(undefined);
    setExclusiveId(undefined);
    setExclusivePartnerCode(undefined);
  };


  {/*const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 200,
      editable: true,
    },
    {
      field: "lastname",
      headerName: "Lastname",
      width: 200,
      editable: true,
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      editable: true,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 160,
      editable: true,
    },
    {
      field: "date",
      headerName: "Date",
      width: 150,
      editable: true,
    },
    {
      field: "valid_1",
      headerName: "Input",
      width: 80,
      editable: true,
    },
    {
      field: "valid_2",
      headerName: "Validation",
      width: 120,
      editable: true,
    },
    {
      field: "valid_2_score",
      headerName: "Score",
      width: 80,
      editable: true,
    },
    {
      field: "valid_3",
      headerName: "Output",
      width: 80,
      editable: true,
    },
    {
      field: "revenue",
      headerName: "Revenue",
      width: 80,
      editable: true,
    },
  ];*/}

  if (!isLoggedIn) return <Signin />;

  // Pass handleNotes function as a prop to the columns array
  const columnsWithActions = columns.map((column) => {
    if (column.field === "actions") {
      return {
        ...column,
        renderCell: (params) => (
          <div>
          </div>
        ),
      };
    }
    return column;
  });
  let index = 0;
  return (
    <div>
      <Box>
        <h1>Leads - {campaign}</h1>
        {userType === "admin" && (
          <div className="customDatePicker" style={{ marginBottom: "10px" }}>
            <TextField
              size="small"
              label="Search"
              type="search"
              variant="outlined"
              style={{  marginBottom: "10px" }}
              value={searchInput}
              onChange={handleSearchInputChange}
            />
            {/*&nbsp;
            <Select
              size="small"
              value={searchCampaign}
              onChange={handleSearchCampaignChange}
            >
              <MenuItem value="null">All Campaigns</MenuItem>
              {campaigns.map((campaign) => (
              <MenuItem key={campaign._id} value={campaign._id}>
                {campaign.name}
              </MenuItem>
            ))}
          </Select>*/}
            &nbsp;
            <DatePicker format="DD/MM/YYYY" onChange={handleSearchStartDateChange} />
            &nbsp;
            <DatePicker format="DD/MM/YYYY" onChange={handleSearchEndDateChange} />
            &nbsp;
            <Select
              size="small"
              value={searchStep}
              onChange={handleSearchStepChange}
            >
              <MenuItem value="null">Steps</MenuItem>
              <MenuItem value="1">Input</MenuItem>
              <MenuItem value="2">Validation</MenuItem>
              <MenuItem value="3">Output</MenuItem>
              <MenuItem value="666">Discarded</MenuItem>
            </Select>
            &nbsp;
            <Button
              variant="contained"
              color="primary"
              onClick={handleExportXls}
              style={{ backgroundColor: "#0AF17D", color: "white" }}
            >
              Export Excel
            </Button>
            &nbsp;
            <Button
              variant="contained"
              color="primary"
              onClick={fetchData}
              style={{ backgroundColor: "#0AF17D", color: "white" }}
            >
            Reload  
            </Button>
          </div>
        )}
        <Box>
        <DataGrid
          className="dataGrid"
          isCellEditable={() => false}
          rows={rows}
          columns={columns}
          //columns={columnsWithActions} // Use the modified columns array
          initialState={{ pagination: { paginationModel: { pageSize: 15 } } }}
          pageSizeOptions={[15]}
          disableRowSelectionOnClick
          disableSelectionOnClick
          disableColumnMenu
          getRowClassName={(params) => {
            return params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow' : 'oddRow'
            //params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow' : 'oddRow'
          }}
        />
        </Box>

      </Box>
      <ToastContainer
        hideProgressBar
        icon={<></>}
        closeButton={false}
        toastStyle={{ background: "transparent", boxShadow: "none" }}
      />
    </div>
  );
}
